<template>
  <login />
</template>

<script>
  import login from '../components/Login.vue'

  export default {
    name: 'Home',

    components: {
      login,
    },
  }
</script>

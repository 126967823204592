<template>
<nav>
    <v-app-bar app color="black" dark elevate-on-scroll dense>
        <div class="d-flex align-center">

            <v-btn v-if="!barra" @click="drawer = !drawer" fab x-small>
                <v-app-bar-nav-icon x-small color="white">
                </v-app-bar-nav-icon>
            </v-btn>
            <v-img alt="Vuetify Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100" src="domotica.png" width="100" />
        </div>

        <v-spacer></v-spacer>
        <v-badge class="mx-3" v-if="$store.state.array_carrito.length != 0" :content="$store.state.array_carrito.length" :value="$store.state.array_carrito.length" color="green" overlap>
            <v-btn @click="$store.commit('dial_carrito', true)" fab x-small color="white">
                <v-icon medium color="error">mdi-cart-variant</v-icon>
            </v-btn>
        </v-badge>
        <v-icon v-if="$store.state.configuracion.mostrar_yape" color="blue" @click="dialog_modo_pago = true">mdi-cash-multiple</v-icon>

        <v-btn :href="`https://api.whatsapp.com/send?phone=$+51902135696&text=Hola Necesito Ayuda`" target="_blank" text>
            <v-icon color="green">mdi-whatsapp</v-icon>
        </v-btn>

    </v-app-bar>

    <v-navigation-drawer v-if="!barra" v-model="drawer" temporary app>

        <v-list-item class="px-3">
            <v-list-item-avatar>
                <v-img src="https://firebasestorage.googleapis.com/v0/b/mitienda-f5ef8.appspot.com/o/carpetaiconos%2Ficono25?alt=media&token=9ead45f5-7735-4386-8647-e1d80cc913ab"></v-img>
            </v-list-item-avatar>

            <v-list-item-title class="mb-2">
                <v-row no-gutters>
                    <v-col cols="12">
                        <span style="font-size: 0.8em; color: #FF2D00;">Usuario:</span>

                    </v-col>
                    <v-col cols="12">
                        <span style="font-size: 0.8em ; text-transform:uppercase">{{ nombreusuario }}</span>
                    </v-col>
                </v-row>

            </v-list-item-title>

            <v-btn icon @click.stop="drawer = !drawer">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
            <v-list-item link @click.prevent="router('panel')">
                <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Home</v-list-item-title>
                </v-list-item-content>

            </v-list-item>

            <v-list-group :value="listaMenu" prepend-icon="mdi-cash-register" v-if="$store.state.permisos.modulocaja">
                <template v-slot:activator>
                    <v-list-item-title>Ventas</v-list-item-title>
                </template>
                <v-container>
                    <v-list-item link @click.prevent="opcionCaja()">
                        <v-list-item-icon>
                            <v-icon>mdi-cash-register</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title> Caja</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link @click.prevent="opcionCaja2()" disabled>
                        <v-list-item-icon>
                            <v-icon>mdi-food</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title> Fast Food</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link @click.prevent="router('flujocaja')">
                        <v-list-item-icon>
                            <v-icon>mdi-cash-register</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title> Flujo de Caja</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="$store.state.configuracion.proformas" link @click.prevent="router('reporte_proformas')">
                        <v-list-item-icon>
                            <v-icon>mdi-cash-register</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title> Proformas</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-container>
            </v-list-group>
            <v-list-group v-if="$store.state.permisos.moduloproductos" :value="listaMenu" prepend-icon="mdi-package-variant-closed">
                <template v-slot:activator>
                    <v-list-item-title>Productos</v-list-item-title>
                </template>
                <v-container>
                    <v-list-item link @click.prevent="router('productos')">
                        <v-list-item-icon>
                            <v-icon>mdi-package-variant-closed</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title> Productos</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link @click.prevent="router('categorias')">
                        <v-list-item-icon>
                            <v-icon>mdi-package-variant-closed</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title> Categorias</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="$store.state.baseDatos.restaurante" link @click.prevent="router('detalles_platos')">
                        <v-list-item-icon>
                            <v-icon>mdi-silverware-fork-knife</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title> Detalle Platos</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="$store.state.baseDatos.restaurante" link @click.prevent="router('carta2')">
                        <v-list-item-icon>
                            <v-icon>mdi-package-variant-closed</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title> Carta Opcional</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-container>
            </v-list-group>
            <v-list-group v-if="$store.state.permisos.moduloclientes" :value="listaMenu" prepend-icon="mdi-account-search">
                <template v-slot:activator>
                    <v-list-item-title>Clientes</v-list-item-title>
                </template>
                <v-container>
                    <v-list-item link @click.prevent="router('clientes')">
                        <v-list-item-icon>
                            <v-icon>mdi-account-search</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Lista Clientes</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link @click.prevent="router('comprasClientes')">
                        <v-list-item-icon>
                            <v-icon>mdi-account-search</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Historial Compra</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-container>
            </v-list-group>

            <v-list-group v-if="$store.state.permisos.modulo_empleados" :value="listaMenu" prepend-icon="mdi-account-convert">
                <template v-slot:activator>
                    <v-list-item-title>Empleados</v-list-item-title>
                </template>
                <v-container>
                    <v-list-item disabled link @click.prevent="router('clientes')">
                        <v-list-item-icon>
                            <v-icon>mdi-clipboard-list</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Lista Empleados</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item disabled link @click.prevent="router('comprasClientes')">
                        <v-list-item-icon>
                            <v-icon>mdi-list-status</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Control Asistencia</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-container>
            </v-list-group>

            <v-list-group v-if="$store.state.permisos.modulokardex" :value="listaMenu" prepend-icon="mdi-dump-truck">
                <template v-slot:activator>
                    <v-list-item-title>Kardex</v-list-item-title>
                </template>
                <v-container>

                    <v-list-item link @click.prevent="router('movimientos_kardex')">
                        <v-list-item-icon>
                            <v-icon>mdi-swap-horizontal</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Kardex</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click.prevent="router('insumos')">
                        <v-list-item-icon>
                            <v-icon>mdi-cookie</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Lista Insumos</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click.prevent="router('costeo_receta')">
                        <v-list-item-icon>
                            <v-icon>mdi-factory</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Costo x Receta</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click.prevent="router('costeo_insumos')">
                        <v-list-item-icon>
                            <v-icon>mdi-factory</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Compras de Insumo</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-container>
            </v-list-group>

            <v-list-group v-if="$store.state.baseDatos.restaurante" :value="listaMenu" prepend-icon="mdi-silverware-fork-knife">
                <template v-slot:activator>
                    <v-list-item-title>RESTAURANTE</v-list-item-title>
                </template>
                <v-container>
                    <v-list-item v-if="true" link @click.prevent="router('pedidos_app')">
                        <v-list-item-icon>
                            <v-icon>mdi-cellphone-nfc</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Ped.Ya - Rappy</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="$store.state.permisos.modulomesas" link @click.prevent="router('delivery')">
                        <v-list-item-icon>
                            <v-icon>mdi-moped-electric-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Delivery</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="false" link @click.prevent="router('delivery_pc')">
                        <v-list-item-icon>
                            <v-icon>mdi-moped-electric-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Delivery PC</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="$store.state.permisos.modulomesas" link @click.prevent="router('lista_mesas')">
                        <v-list-item-icon>
                            <v-icon>mdi-table-chair</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title> Mesas</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="$store.state.permisos.modulococina" link @click.prevent="router('cocina')">
                        <v-list-item-icon>
                            <v-icon>mdi-food-turkey</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title> Cocina</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="$store.state.permisos.modulococina" link @click.prevent="router('barra')">
                        <v-list-item-icon>
                            <v-icon>mdi-glass-cocktail</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Barra</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-container>
            </v-list-group>

            <v-list-group v-if="$store.state.permisos.modulosunat" :value="listaMenu" prepend-icon="mdi-text-box-multiple-outline">
                <template v-slot:activator>
                    <v-list-item-title>SUNAT</v-list-item-title>
                </template>
                <v-container>

                    <v-list-item @click.prevent="router('boletaFactura')">
                        <v-list-item-icon>
                            <v-icon>mdi-text-box-multiple-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Boletas, Facturas</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click.prevent="router('creditoDebito')">
                        <v-list-item-icon>
                            <v-icon>mdi-text-box-multiple-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>N.Credito</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click.prevent="router('resumenbaja')">
                        <v-list-item-icon>
                            <v-icon>mdi-text-box-multiple-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Resumen Bajas</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click.prevent="router('ticket')">
                        <v-list-item-icon>
                            <v-icon>mdi-web-box</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Tickets</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-container>
            </v-list-group>

            <v-list-group v-if="$store.state.permisos.moduloreportes" :value="listaMenu" prepend-icon="mdi-finance">
                <template v-slot:activator>
                    <v-list-item-title>REPORTES</v-list-item-title>
                </template>
                <v-container>
                    <v-list-item @click.prevent="router('ventasxempleado')" v-if="$store.state.configuracion.comisiones">
                        <v-list-item-icon>
                            <v-icon>mdi-text-box-multiple-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Ventas x Emp.</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click.prevent="router('comprobantes')">
                        <v-list-item-icon>
                            <v-icon>mdi-text-box-multiple-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Comprobantes</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click.prevent="router('reportes')">
                        <v-list-item-icon>
                            <v-icon>mdi-finance</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Ranking Semanal</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click.prevent="router('reporteVentaDetalle')">
                        <v-list-item-icon>
                            <v-icon>mdi-cash-100</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title> X Producto</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click.prevent="router('reporteVentaDetalle')">
                        <v-list-item-icon>
                            <v-icon>mdi-cash-100</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title> Reg.Contables</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-container>
            </v-list-group>

            <v-spacer></v-spacer>
            <v-divider></v-divider>

            <v-list-item v-if="user == 'ivanac1992@domotica.com'" @click.prevent="router('panelgeneral')">
                <v-list-item-icon>
                    <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Admin</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="$store.state.permisos.moduloempresa" link @click.prevent="router('miempresa')">
                <v-list-item-icon>
                    <v-icon>mdi-cog-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Configuracion</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item @click.prevent="accion('Cerrar Sesion')">
                <v-list-item-icon>
                    <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Cerrar Sesion</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

        </v-list>
    </v-navigation-drawer>

    <div class="text-center">
        <v-dialog v-model="dialog" width="500">

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    NOTIFICACIONES
                </v-card-title>

                <v-card class="mx-auto" max-width="500">
                    <v-list two-line>
                        <v-list-item-group v-model="selected" active-class="red--text" multiple>
                            <template v-for="(item, index) in items">
                                <v-list-item :key="item.title">
                                    <template v-slot:default="{ active }">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.title"></v-list-item-title>

                                            <v-list-item-subtitle class="text--primary" v-text="item.headline"></v-list-item-subtitle>

                                            <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-list-item-action-text v-text="item.action"></v-list-item-action-text>

                                            <v-icon v-if="!active" color="grey lighten-1">
                                                mdi-star-outline
                                            </v-icon>

                                            <v-icon v-else color="yellow darken-3">
                                                mdi-star
                                            </v-icon>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>

                                <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                        ENTENDIDO
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <v-dialog v-model="dialog_modo_pago" scrollable max-width="400px" transition="dialog-bottom-transition">
        <v-card class="pa-2">
            <v-row dense>
                <v-col cols="6">
                    <v-card @click.prevent="abre_modo('yape')">
                        <v-container>
                            <v-img class="mx-auto" height="70" width="70" src="/yape.png"></v-img>
                        </v-container>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card @click.prevent="abre_modo('transferencia')">
                        <v-container>
                            <v-img class="mx-auto" height="70" width="70" src="/bank.png"></v-img>
                        </v-container>
                    </v-card>
                </v-col>

            </v-row>
        </v-card>
    </v-dialog>
    <dialog_modos v-if="dial_modos_" :data="modo_pago" @cierra="dial_modos_ = false" />
    <mensaje />
    <dialogAlerta />
    <dialogoprogress />
    <dialogosnackbar />
</nav>
</template>

<script>
// @ is an alias to /src
import firebase from 'firebase'
import {
    obtenerBD,
    allConfigura,
    allEmpresas,
    allModoPago,
    allBancos,
    consultaArchivo,
    obtenerImpresoras,
    allUsuarios,
    allnotificaciones,
    allProductos,
    allClientes,
    obtenerSeries,
    allCategorias,
    all_grupo_obs
} from '../db'
import dialog_modos from '@/components/dialogos/dialog_modos_pagos'
import store from '@/store/index'
import mensaje from '@/components/dialogos/dialogMensaje'
import dialogAlerta from '@/components/dialogos/dialogAlerta'
import dialogoprogress from '@/components/dialogos/dialogoprogress'
import dialogosnackbar from '@/components/dialogos/dialogosnackbar'
import imageToBase64 from 'image-to-base64/browser'
import isMobile from 'mobile-device-detect'
export default {
    name: 'barrasuperior',

    components: {
        mensaje,
        dialogAlerta,
        dialogoprogress,
        dialogosnackbar,
        dialog_modos
    },
    data() {
        return {
            dialog_modo_pago: false,
            modo_pago: [],
            dial_modos_: false,
            listaMenu: false,
            drawer: false,
            user: '',
            nombreusuario: '',
            barra: true,
            bd: '',
            dialog: false,
            messages: 0,
            selected: [],
            items: [{
                action: '15 min',
                headline: 'Tenemos novedades para Ti',
                subtitle: 'Facturacion Domotica System esta habilitado para ti, los 365 dias',
                title: 'BIENVENIDO!',
            }, ],
        }
    },
    beforeCreate() {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.user = user.email
                this.nombreusuario = user.email.slice(0, -13)
                this.barra = false
                this.obtenerDatausuario(user.email)
            } else {
                this.user = null
                store.commit('BD', '')

            }
        })
    },
    methods: {
        abre_modo(modo) {
            this.modo_pago = modo
            this.dial_modos_ = true
        },
        accion(accion) {
            if (accion == "Cerrar Sesion") {
                firebase.auth().signOut().then(() => {
                    this.$router.push({
                        name: 'Home'
                    })
                    this.barra = true
                    store.commit('BD', '')
                    store.commit('permisos', '')
                })
            }
            if (accion == "paneladmin") {
                this.$router.push({
                    name: 'paneladmin'
                })
            }
        },
        router(view) {
            this.$router.push({
                name: view
            })
        },

        async obtenerDatausuario(user) {
            var snapshot = await allUsuarios().orderByChild('correo').equalTo(user).once("value")
            if (snapshot.exists()) {
                snapshot.forEach((item) => {
                    this.bd = item.val().bd
                    store.commit('permisos', item.val())
                })
                this.obtenerCliente(this.bd)
            }
        },
        async obtenerCliente(BD) {
            var snapshot = await obtenerBD(BD).once("value")
            store.commit('BD', snapshot.val())
            store.commit('esmovil', isMobile.isAndroid)
            if (snapshot.exists()) {
                this.obtenconfiguracion()
                this.notificaciones(snapshot.val())
                this.obtenproductos()
            } else {
                alert('Empresa sin informacion')
            }
        },
        async obtenconfiguracion() {
            allConfigura().once('value').then((snapshot) => {
                if (snapshot.exists()) {
                    store.commit('configuracion', snapshot.val())
                    var nom_cocina = ''
                    var nom_barra = ''
                    var nom_cocina2 = ''
                    var nom_barra2 = ''
                    var array = snapshot.val().impresoras
                    for (var i = 0; i < array.length; i++) {
                        if (array[i].observacion == "cocina") {
                            if (nom_cocina != '') {
                                nom_cocina = nom_cocina + ',' + array[i].tipo
                            } else {
                                nom_cocina = nom_cocina + array[i].tipo
                            }
                        }
                        if (array[i].observacion == "barra") {
                            if (nom_barra != '') {
                                nom_barra = nom_barra + ',' + array[i].tipo
                            } else {
                                nom_barra = nom_barra + array[i].tipo
                            }
                        }
                        if (array[i].observacion == "cocina2") {
                            if (nom_barra != '') {
                                nom_cocina2 = nom_cocina2 + ',' + array[i].tipo
                            } else {
                                nom_cocina2 = nom_cocina2 + array[i].tipo
                            }
                        }
                        if (array[i].observacion == "barra2") {
                            if (nom_barra != '') {
                                nom_barra2 = nom_barra2 + ',' + array[i].tipo
                            } else {
                                nom_barra2 = nom_barra2 + array[i].tipo
                            }
                        }
                    }
                    var data = {
                        nom_cocina: nom_cocina,
                        nom_barra: nom_barra,
                        nom_cocina2: nom_cocina2,
                        nom_barra2: nom_barra2
                    }
                    store.commit('impresoras_restau', data)
                }
            })
            obtenerImpresoras().once("value").then((snapshot) => {
                if (snapshot.exists()) {
                    store.commit("configImpresora", snapshot.val())
                }
            })
            consultaArchivo().once("value").then((snapshot) => {
                if (snapshot.val() != null && snapshot.val().logoEmpresa != '') {
                    imageToBase64(snapshot.val().logoEmpresa) // Image URL
                        .then(
                            (response) => {
                                store.commit("logoempresa", response)
                            })
                        .catch(
                            (error) => {
                                console.log(error)
                            }
                        )
                }
            })
            if (store.state.permisos.modulocaja) {
                obtenerSeries().once("value").then((snapshot) => {
                    if (snapshot.exists()) {
                        store.commit("seriesdocumentos", snapshot.val())
                    }
                })
                allModoPago().once("value").then((snapshot) => {
                    if (snapshot.exists()) {
                        store.commit("modopagos", snapshot.val())
                    }
                })
                allBancos().once("value").then((snapshot) => {
                    var arraybanco = []
                    if (snapshot.exists()) {
                        snapshot.forEach((item) => {
                            arraybanco.push(item.val())
                        })
                        store.commit("bancos", arraybanco)
                    }
                })
            }

        },
        obtenproductos() {
            allProductos().on('value', (snapshot) => {
                let array = [];
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        let data = item.val();
                        array.push(data);

                    });
                    store.commit('productos', array)
                }
            })
            allCategorias().on('value', (snapshot) => {
                let array = [];
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        let data = item.val();
                        let key = item.key;
                        data.id = key
                        array.push(data);
                    });
                    store.commit('categorias', array)
                }
            })
            if (store.state.permisos.moduloclientes || store.state.permisos.modulocaja) {
                allClientes().on('value', (snapshot) => {
                    let array = [];
                    if (snapshot.exists()) {
                        snapshot.forEach((item) => {
                            let data = item.val();
                            array.push(data);
                        });
                        store.commit('clientes', array)
                    }
                })
            }
            all_grupo_obs().on('value', (snapshot) => {
                let array = [];
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        let data = item.val();
                        array.push(data);
                    });
                    store.commit('observaciones', array)
                }
            })
        },

        opcionCaja() {
            if (store.state.baseDatos.caja2) {
                this.$router.push({
                    name: 'caja'
                })
            }
        },
        opcionCaja2() {
            if (store.state.baseDatos.caja2) {
                this.$router.push({
                    name: 'caja_pc'
                })
            }
        },
        notificaciones(items) {
            allnotificaciones(items.bd).on('value', (snapshot) => {
                if (snapshot.exists()) {
                    var data = snapshot.val()
                    store.commit('arraynotifica', data)
                    if (data.dialogoactualiza) {
                        store.commit('dialogomensaje')
                    }
                    if (data.dialogoAlerta) {
                        store.commit('dialogoAlerta')
                    }
                }
            })
        },

    },

}
</script>

import Vue from 'vue'
import './plugins/fontawesome'
import './plugins/fontawesome'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import { firestorePlugin } from 'vuefire'
import firebase from 'firebase'
import Croppa from 'vue-croppa';
import VueGeolocation from 'vue-browser-geolocation'

import GmapVue from 'gmap-vue'

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyAv9tF3uvXEgAxyvP5JiRejLfHi8UEWvV4',
    libraries: 'places',
    v: '3.26',
    customCallback: 'MyCustomCallback',
  },

  autoBindAllEvents: false,
  installComponents: true,

  dynamicLoad: false,
})
Vue.use(VueGeolocation)

Vue.use(Croppa);

Vue.use(firestorePlugin)

Vue.config.productionTip = false

let app = null

firebase.auth().onAuthStateChanged(() => {
  if (!app){
   app = new Vue({
      router,
      store,
      vuetify,
      render:h => h(App)
    }).$mount('#app')
  }
})

 